import Vue from 'vue'
import App from './App.vue'
import router from './router'

import headerBlock from './components/include/header'
import footerBlock from './components/include/footer'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.component('header-block', headerBlock)
Vue.component('footer-block', footerBlock)

new Vue({
  router,

  methods: {
    // closeTabs () {
    //   const createModal = document.getElementById('modal')
    //   let count = 0
    //   window.addEventListener('mousemove', function (e) {
    //     if (e.clientY < 20 && !count) {
    //       count++
    //       createModal.style.display = 'block'
    //       document.addEventListener('click', (e) => {
    //         if (e.target === createModal) {
    //           createModal.remove()
    //         }
    //       })
    //     }
    //   })
    // }
  },

  mounted () {
    // this.closeTabs()
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')
